<template>
  <v-container>
    <v-row>
      <v-col cols="6" offset="3">
        <template>
          <v-card>
            <v-card-title>
              Activity Log
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :loading="isLoading"
              :items="activities"
              :headers="headers"
              class="elevation-1"
              dense
              loading-text="Loading... Please wait"
              :search="search"
            >
              <template v-if="users" v-slot:item.userId="{ item }">
                <span>
                  {{ showUser(item.userId) }}
                </span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>
                  {{ formatDate(item.createdAt) }}
                </span>
              </template>
              <template v-slot:item.itemId="{ item }">
                <router-link
                  v-if="item.itemType == 'claim'"
                  :to="`/mtpl/${item.itemId}/`"
                >
                  <span>{{ item.itemId }}</span></router-link
                >
                <span v-else>{{ item.itemId }}</span>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      search: '',
      isLoading: true,
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
        },
        {
          text: 'Actor',
          value: 'userId',
        },
        {
          text: 'Action',
          value: 'type',
        },
        {
          text: 'Item Type',
          value: 'itemType',
        },
        {
          text: 'Item Id',
          value: 'itemId',
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('activity/getActivities')
    this.$store.dispatch('user/getUsers').then((this.isLoading = false))
  },
  computed: {
    ...mapState({
      activities: (state) => state.activity.activities,
      users: (state) => state.user.users,
    }),
    combinedProperties() {
      return `${this.activities}${this.users}`
    },
  },
  watch: {
    combinedProperties() {
      this.isLoading = false
    },
  },
  methods: {
    formatDate(item) {
      return dayjs(item).format('YYYY-MM-DD HH:MM')
    },
    showUser(userId) {
      let user = this.users.find((u) => u.id == userId)
      return `${user.firstName} ${user.lastName}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
